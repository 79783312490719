/* eslint-disable react/no-unescaped-entities */
import Typography from '@mui/material/Typography';
import React from 'react';
import Link from '@mui/material/Link';

const Terms = () => {
  return (
    <div className="highlightLinks">
      <p>Last updated: May 31, 2024</p>
      <p>
        <b>
          This page outlines the Terms of Service for all TravelFeed products
          used to access and contribute TravelFeed content, namely the
          TravelFeed website and app, the Truvvl app and TravelFeed Hosting.
        </b>
      </p>
      <p>
        <b>
          Before using any of the provided services, you are required to read,
          understand, and agree to these terms.
        </b>
      </p>
      <Typography gutterBottom variant="h4">
        Unofficial Overview
      </Typography>
      <p>
        <b>
          First, here is an unofficial non-legal overview of everything
          that&#39;s important. This preview does not take the place of reading
          or agreeing to the full legal Terms of Service.
        </b>
      </p>
      <ul>
        <li>
          Travelfeed is a travel platform, community and content distribution
          service;
        </li>
        <li>
          Currently, TravelFeed is supporting automated posting to the following
          third-party blockchains and networks: Hive. TravelFeed can be used to
          publish content to these blockchains, however, user information will
          be stored on our own services;
        </li>
        <li>
          We can modify or delete content only on our own platform, but not on
          third-party blockchains;
        </li>
        <li>
          We do not store private keys (aka &quot;passwords&quot;) for
          third-party blockchain accounts and we can not help you remember or
          recover them;
        </li>
        <li>
          TravelFeed is not intended for use under 18 years of age (in part
          because we can not guarantee prevention of access to adult content or
          prevent access to gambling related activities or prevent access to
          third-party type contracts that may require an adult etc. - we will,
          however, remove this kind of content when reported to us);
        </li>
        <li>
          On a related note, TravelFeed has no ability to verify your age nor
          will it ask for your age;
        </li>
        <li>
          TravelFeed content, logos and trademarks are NOT based on a free for
          all license, you must have written permission to use or change them;
        </li>
        <li>
          TravelFeed does not move money around, we only facilitate transactions
          on third-party blockchains with your permission;
        </li>
        <li>
          TravelFeed only allows original content from users. We will remove
          plagiarised/stolen or content with copyright violations when noticed
          or notified. Reports can be made through the “report” button in the
          three-dots-menu on every post or in the share menu of the Truvvl app.
          We can not remove content or modify transactions on the blockchains
          when using our content distribution service. We reward original
          content, but allow the use of sourced material for citation purposes
          or with a license by the copyright holder, under condition that these
          materials are linked back to the original copyright holder giving
          proper credit and as long as the use of this material is not a
          copyright violation.
        </li>
        <li>
          By posting on TravelFeed, you automatically grant us a non-exclusive,
          transferable, sub-licensable and worldwide licence to host, use,
          distribute, modify, run, copy, publicly display, translate and create
          derivative works of your content.
        </li>
      </ul>
      <p>
        <b>
          Actions we do not tolerate and will get you banned from our website
          and/or app:
        </b>
      </p>
      <ul>
        <li>Fraud;</li>
        <li>Terrorist activities;</li>
        <li>
          Theft (such as posting content you do not have permission to post);
        </li>
        <li>
          Hurting the TravelFeed infrastructure (i.e. malicious software or
          hardware impact such as overburdening of the system);
        </li>
        <li>Distort, corrupt or misuse our code;</li>
        <li>Use TravelFeed to extract data;</li>
        <li>Accessing parts of the site you are not supposed to access.</li>
      </ul>
      <p>
        <b>Some, but not all, risks of operating on TravelFeed are:</b>
      </p>
      <ul>
        <li>
          Risk of you losing your private keys for third-party blockchains and
          thus access to your account including holdings such as, but not
          limited to any token or cryptocurrency;
        </li>
        <li>
          Risk you may see adult themed material. TravelFeed is not meant to be
          a platform to share adult content and once reported, any adult content
          will be removed from our website and app;
        </li>
        <li>
          Risk of third-party internet links outside of TravelFeed that are
          malicious or damaging;
        </li>
        <li>
          Risk of server failure or temporary loss of access to TravelFeed;
        </li>
        <li>
          Risk of a transaction not being sent to blockchains (Such as through
          technical failure by TravelFeed or a third-party such as blockchain
          identification and verification services itself);
        </li>
        <li>
          Risk of coming in contact to unsavory third parties or third-party
          content;
        </li>
        <li>Risk of coming into contact with fraud from third parties.</li>
      </ul>
      <p />
      <Typography gutterBottom variant="h4">
        Legal Terms of Service
      </Typography>
      <p>
        The TravelFeed and Truvvl websites and apps as well as TravelFeed
        Hosting (&quot;TravelFeed&quot;, &quot;we&quot;, &quot;us&quot; or
        &quot;our&quot;) are operated by CurlyCorn LLC.{' '}
      </p>

      <p>
        TravelFeed also offers an optional content distribution service to
        interact with supported third-party blockchains. This interaction
        includes several activities allowed by the underlying protocol. Please
        refer to the documentation of supported third-party blockchains to have
        a better understanding of how they work. The following are the Terms of
        Service ("Terms") for using TravelFeed and the services provided on this
        website or via the app. It is NOT intended as a generic document to
        define the Terms of Service of the use of supported third-party
        blockchains.
      </p>
      <p>
        We may change these terms at any time. When we do, we revise the updated
        date at the top of this page. You acknowledge and agree that it is your
        responsibility to review this page periodically and become aware of
        modifications. If you keep using TravelFeed after a change, that means
        you accept the new terms.
      </p>
      <p>
        By you agreeing to these terms, you are also agreeing to our{' '}
        <Link href="/about/privacy">Privacy Policy</Link> and{' '}
        <Link href="/about/cookies">Cookie Policy</Link>.
      </p>
      <Typography gutterBottom variant="h5">
        1. Account Registration
      </Typography>
      <p>
        <b>
          You can browse TravelFeed via the website or the app without an
          account or log in with an existing account.
        </b>
      </p>
      <p>
        You can browse TravelFeed without an account, but to use some TravelFeed
        features you have to create an account and sign in with a TravelFeed
        account or through a supported third-party service.
      </p>
      <p>
        Please refer to the specific terms of these external services when
        creating an account through them.
      </p>
      <p>
        When you login on the TravelFeed website or the app for the first time
        with a new account some details are stored to allow you to customize
        your experience on the TravelFeed website or app. We never store nor ask
        for the private keys of your accounts on supported third-party
        blockchains.
      </p>
      <p>
        You may choose to link third-party blockchain accouns to TravelFeed and
        give TravelFeed authority to broadcast transactions on your behalf.
        These transactions can include, but are not limited to posts, comments,
        votes, reward claiming and custom json operations. Linking your accounts
        is done automatically if Hivesigner is used to log in. By linking your
        accoounts, you acknowledge that while we are doing our best to protect
        your account, TravelFeed is not responsible for any malicious
        transactions broadcasted by the linked accounts through our service and
        you may revoke the posting authority granted to TravelFeed at any time
        through third-party blockchains.
      </p>
      <Typography gutterBottom variant="h5">
        2. Eligibility
      </Typography>
      <p>
        You must be at least 18 years of age to access or use TravelFeed.
        TravelFeed is not targeted towards, nor intended for use by anyone under
        the age of 18. You must be at least 18 years of age to access or use
        TravelFeed.
      </p>
      <Typography gutterBottom variant="h5">
        3. Copyright and TravelFeed Limited License
      </Typography>
      <p>
        TravelFeed contains data, text, photographs, images, video, audio,
        graphics, articles, comments, software, code, scripts, map coordinates
        and other content supplied by us, the third-party blockchains or our
        licensors, which we call &quot;TravelFeed Content.&quot; TravelFeed
        Content is protected by intellectual property laws, including copyright
        and other proprietary rights of applicable countries. Except as
        explicitly stated in these terms, TravelFeed does not grant any express
        or implied rights to use TravelFeed Content.
      </p>
      <p>
        You are granted a limited, non-exclusive, non-transferable, and
        non-sublicensable license to access and use TravelFeed and TravelFeed
        Content for your personal, non-commercial use. This license is subject
        to these terms and does not include any right to: (a) Distribute,
        publicly perform or publicly display any TravelFeed Content; (b) Modify
        or otherwise make any derivative uses of TravelFeed or TravelFeed
        Content, or any portion thereof; (c) Use any data mining, robots or
        similar data gathering or extraction methods; and (d) Use TravelFeed or
        TravelFeed Content other than for their intended purposes. Any use of
        TravelFeed or TravelFeed Content other than as authorized in these terms
        is strictly prohibited and will terminate the license granted herein.
        This license is revocable at any time.
      </p>
      <Typography gutterBottom variant="h5">
        4. Trademarks
      </Typography>
      <p>
        &quot;TravelFeed&quot;, the TravelFeed logo, Truvvl and the Truvvl logo
        are trademarks of CurlyCorn LLC and may not be copied, imitated or used,
        in whole or in part, without our prior written permission. You may not
        use any metatags or other &quot;hidden text&quot; utilizing
        &quot;TravelFeed&quot; or any other name, trademark or product or
        service name of TravelFeed without our prior written permission. In
        addition, the look and feel of TravelFeed, including, without
        limitation, all page headers, custom graphics, button icons and scripts,
        constitute the service mark, trademark or trade dress of TravelFeed and
        may not be copied, imitated or used, in whole or in part, without our
        prior written permission. All other trademarks, registered trademarks,
        product names and company names or logos mentioned or used on TravelFeed
        are the property of their respective owners and may not be copied,
        imitated or used, in whole or in part, without the permission of the
        applicable trademark holder. Reference to any products, services,
        processes or other information by name, trademark, manufacturer,
        supplier or otherwise does not constitute or imply endorsement,
        sponsorship or recommendation by TravelFeed.
      </p>
      <p>Google Play and the Google Play logo are trademarks of Google LLC. </p>
      <p>
        Tweemoji Graphics licensed under CC-BY 4.0:
        https://creativecommons.org/licenses/by/4.0/
      </p>
      <Typography gutterBottom variant="h5">
        5. Adult-Oriented Content
      </Typography>
      <p>
        TravelFeed is intended for a general audience and, as a result, some
        TravelFeed Content may discuss or depict adult-oriented topics. We
        realize that this content may not be appropriate or desirable for some
        of our readers depending on their current location, age, background or
        personal views. We will remove adult-oriented content from our platform
        when reported to us.
      </p>
      <Typography gutterBottom variant="h5">
        6. Assumption of Risk, Limitations on Liability &amp; Indemnity
      </Typography>
      <p>
        You accept and acknowledge that there are risks associated with
        utilizing an internet-basedservice including, but not limited to, the
        risk of failure of hardware, software and internet connections, the risk
        of malicious software introduction (please click on links cautiously),
        and the risk that third parties may obtain unauthorized access to
        information stored within your account, including, but not limited to
        your private key (as defined below). You accept and acknowledge that
        TravelFeed will not be responsible for any communication failures,
        disruptions, errors, distortions or delays you may experience when using
        the services, however caused.
      </p>
      <p>To combat some of these risks we advise you to:</p>
      <ul>
        <li>
          Click on all links with caution, in particular any links that lead to
          locations outside of travelfeed.com or away from the app.
        </li>
        <li>
          Be extremely careful with storing your private keys and be careful
          which third-party services you give access to private keys.
        </li>
      </ul>
      <p>
        We will use reasonable endeavours to verify the accuracy of any
        information on the service but we make no representation or warranty of
        any kind, express or implied, statutory or otherwise, regarding the
        contents of the service, information and functions made accessible
        through the service, any hyperlinks to third-party websites, nor for any
        breach of security associated with the transmission of information
        through the service or any website linked to by the service.
      </p>
      <p>
        We will not be responsible or liable to you for any loss and take no
        responsibility for and will not be liable to you for any use of our
        services, including but not limited to any losses, damages or claims
        arising from: (a) User error such as forgotten passwords, incorrectly
        constructed transactions, or mistyped supported third-party blockchain
        addresses; (b) Server failure or data loss; (c) Corrupted account files;
        (d) Unauthorized access to applications; (e) Any unauthorized third
        party activities, including without limitation the use of viruses,
        phishing, brute-force attacks or other means of attack against the
        service or services.
      </p>
      <p>
        We make no warranty that the service or the server that makes it
        available, are free of viruses or errors, that its content is accurate,
        that it will be uninterrupted, or that defects will be corrected. We
        will not be responsible or liable to you for any loss of any kind, from
        action taken, or taken in reliance on material, or information,
        contained on the service.
      </p>
      <p>
        Subject to what is stated in paragraph &#39;Limitation of Liability&#39;
        below, any and all indemnities, warranties, terms and conditions
        (whether express or implied) are hereby excluded to the fullest extent
        permitted under applicable law.
      </p>
      <p>
        We will not be liable, in contract, or tort (including, without
        limitation, negligence), other than where we have been fraudulent or
        made negligent misrepresentations.
      </p>
      <p>
        Nothing in these terms excludes or limits liability for death or
        personal injury caused by negligence, fraudulent misrepresentation, or
        any other liability which may not otherwise be limited or excluded under
        applicable law.
      </p>
      <Typography gutterBottom variant="h5">
        7. Agreement to Hold TravelFeed Harmless
      </Typography>
      <p>
        You agree to hold TravelFeed harmless (and each of our officers,
        directors, members, employees, agents and affiliates) from any claim,
        demand, action, damage, loss, cost or expense, including without
        limitation reasonable legal fees, arising out or relating to:
      </p>
      <ol>
        <li>Your use of, or conduct in connection with, our services;</li>
        <li>Any feedback or submissions you provide;</li>
        <li>Your violation of these terms;</li>
        <li>Violation of any rights of any other person or entity.</li>
      </ol>
      <p>
        If you are obligated to indemnify us, we will have the right, in our
        sole discretion, to control any action or proceeding (at our expense)
        and determine whether we wish to settle it.
      </p>
      <Typography gutterBottom variant="h5">
        8. No Liability For Third-Party Services And Content
      </Typography>
      <p>
        In using our services, you may view content or utilize services provided
        by third parties, including links to web pages and services of such
        parties (&quot;third-party content&quot;). We do not control, endorse or
        adopt any third-party content and will have no responsibility for
        third-party content including, without limitation, material that may be
        misleading, incomplete, erroneous, offensive, indecent or otherwise
        objectionable in your jurisdiction. In addition, your dealings or
        correspondence with such third parties are solely between you and the
        third parties. We are not responsible or liable for any loss or damage
        of any sort incurred as a result of any such dealings and you understand
        that your use of Third-party Content, and your interactions with third
        parties, is at your own risk.
      </p>
      <Typography gutterBottom variant="h5">
        9. Advertisement And Affiliate Links
      </Typography>
      <p>
        TravelFeed posts, sites, social media posts and applications may
        contain, display or deliver advertising and sponsorships, including the
        display of advertising and affiliate links within user-generated
        content. Advertisers and sponsors are responsible for ensuring that
        material submitted for inclusion is accurate and complies with
        applicable laws. We are not responsible for the illegality or any error,
        inaccuracy or problems in the advertiser’s or sponsor’s materials.
      </p>
      <Typography gutterBottom variant="h5">
        10. The TravelFeed Services
      </Typography>
      <p>
        As described in more detail below, the services, among other things,
        provide in-browser (or otherwise local), app, software that (a)
        Generates supported third-party blockchain account addresses and
        encrypted private keys (defined below), and (b) Facilitates the
        submission of supported third-party blockchain transaction data to the
        supported third-party blockchain networks without requiring you to
        access the supported third-party blockchain command line interface.
      </p>
      <ol>
        <li>
          <b>Account names and private keys:</b> When you create an account for
          third-party blockchains, you generate a cryptographic private and
          public key pair that you may use to send and receive their
          cryptocurrencies and tokens via their networks. The private key
          uniquely matches the account name and must be used in connection with
          the account name to authorize the transfer of supported third-party
          blockchain currencies from that account. You are solely responsible
          for maintaining the security of your private key and any password
          phrase associated with your wallet or TravelFeed account. You must
          keep your account, password phrase and private key access information
          secure. Failure to do so may result in the loss of control of any
          tokens and cryptocurrencies associated with the wallet.
        </li>
        <li>
          <b>No Password Retrieval:</b> TravelFeed does not receive or store
          your account password for third-party blockchains, or the unencrypted
          keys and addresses. Therefore, we cannot assist you with account
          password retrieval. You are solely responsible for remembering your
          account password. If you have not safely stored a backup of any
          account names and password pairs maintained in your account, you
          accept and acknowledge that any supported third-party blockchain token
          or cryptocurrency you have associated with such account will become
          inaccessible if you do not have your account password.
        </li>
        <li>
          <b>Transactions:</b> In order to be completed, all proposed supported
          third-party blockchain transactions must be confirmed and recorded in
          the supported third-party blockchain public ledger via the supported
          third-party blockchain distributed consensus network (a peer-to-peer
          economic network that operates on a cryptographic protocol), which is
          not owned, controlled or operated by TravelFeed. The supported third
          party blockchain network is operated by a decentralized network of
          independent third parties. TravelFeed has no control over the
          supported third-party blockchain networks and therefore cannot and
          does not ensure that any transaction details you submit via the
          services will be confirmed via the supported third-party blockchain
          network. You acknowledge and agree that the transaction details you
          submit via the services may not be completed, or may be substantially
          delayed, by the network. You may use the services to submit these
          details to the network.
        </li>
        <li>
          <b>
            No Storage or Transmission of supported third-party blockchain
            tokens or cryptocurrencies in any of its forms is an intangible,
            digital asset:
          </b>{' '}
          They exist only by virtue of the ownership record maintained in the
          supported third-party blockchain networks. The service does not store,
          send or receive supported third-party blockchain currencies or tokens.
          Any transfer of title that might occur in any way occurs on the
          decentralized ledger within the supported third-party blockchain
          network and not within the TravelFeed services. We do not guarantee
          that our service can affect the transfer of title or right in any
          supported third-party blockchain token or currency.
        </li>
        <li>
          <b>Relationship:</b> Nothing in these terms is intended to nor shall
          create any partnership, joint venture, agency, consultancy or
          trusteeship, you and TravelFeed being with respect to one another are
          independent contractors.
        </li>
        <li>
          <b>Accuracy of Information:</b> You represent and warrant that any
          information you provide via these services is accurate and complete.
          You accept and acknowledge that TravelFeed is not responsible for any
          errors or omissions that you make in connection with any supported
          third-party blockchain transaction initiated via the services, for
          instance, if you mistype an account name or otherwise provide
          incorrect information.
        </li>
      </ol>
      <p>
        We strongly encourage you to review your transaction details carefully
        before completing them via these services.
      </p>
      <ul>
        <li>
          <b>No Cancellations or Modifications:</b> Once transaction details
          have been submitted to the supported third-party blockchain network
          via the services, the services cannot assist you to cancel or
          otherwise modify your transaction details. TravelFeed has no control
          over the supported third-party blockchain network and does not have
          the ability to facilitate any cancellation or modification requests.
        </li>
      </ul>
      <p>
        While a transaction submitted to the supported third-party blockchain
        network can not be modified or canceled, you are allowed and encouraged
        to make new transactions that can change the present state of the
        presented information on interfaces to the supported third-party
        blockchain blockchain (such as travelfeed.com or the app) this may
        assist you with mistakes in written content, votes, delegation etc.
      </p>
      <ul>
        <li>
          <b>Taxes:</b> It is your responsibility to determine what, if any,
          taxes apply to the transactions you for which you have submitted
          transaction details via the services, and it is your responsibility to
          report and remit the correct tax to the appropriate tax authority. You
          agree that TravelFeed is not responsible for determining whether taxes
          apply to your supported third-party blockchain transactions or for
          collecting, reporting, withholding or remitting any taxes arising from
          any supported third-party blockchain transactions.
        </li>
      </ul>
      <Typography gutterBottom variant="h5">
        11. User Conduct
      </Typography>
      <p>
        When accessing or using these services, you agree that you will not
        commit any unlawful act, and that you are solely responsible for your
        conduct while using our services. Without limiting the generality of the
        foregoing, you agree that you will not:
      </p>
      <ol>
        <li>
          Use our services in any manner that could interfere with, disrupt,
          negatively affect or inhibit other users from fully enjoying our
          services, or that could damage, disable, overburden or impair the
          functioning of our services or the supported third-party blockchain
          network in any manner;
        </li>
        <li>
          Use our services to pay for, support or otherwise engage in any
          illegal activities, including, but not limited to illegal gambling,
          fraud, money- laundering, or terrorist activities;
        </li>
        <li>
          Use any robot, spider, crawler, scraper or other automated means or
          interface not provided by us to access our services or to extract
          data;
        </li>
        <li>
          Use or attempt to access or use another user’s account without
          authorization;
        </li>
        <li>
          Attempt to circumvent any content filtering techniques we employ, or
          attempt to access any service or area of our services that you are not
          authorized to access;
        </li>
        <li>
          Introduce to the services any virus, trojan, worms, logic bombs or
          other harmful material;
        </li>
        <li>
          Develop any third-party applications that interact with our services
          without our prior written consent;
        </li>
        <li>Provide false, inaccurate, or misleading information; or</li>
        <li>
          Encourage or induce any third-party to engage in any of the activities
          prohibited under this section;
        </li>
        <li>
          Reverse engineer any aspect of TravelFeed or do anything that might
          discover source code or bypass or circumvent measures employed to
          prevent or limit access to any TravelFeed Content, area or code of
          TravelFeed.
        </li>
      </ol>
      <Typography gutterBottom variant="h5">
        12. Copyright Complaints
      </Typography>
      <p>
        TravelFeed respects the intellectual property of others by requiring all
        users to submit only content where they have the necessary licenses,
        rights, consents, releases, and permissions to use and to authorize
        TravelFeed and any users of TravelFeed to use the provided content in
        any manner contemplated by these Terms of Service. If you believe that a
        user has breached our Terms of Service by copying your work in a way
        that constitutes copyright infringement, you may notify TravelFeed by
        contacting:{' '}
        <a
          href="mailto:contact@travelfeed.com"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          contact@travelfeed.com
        </a>
        . Reports about specific posts can also be made through the “report”
        button in the three-dots-menu on every post on TravelFeed or in the
        share menu of the Truvvl app. We will review and remove any reported
        copyright infringements within a reasonable amount of time.
      </p>
      <p>
        Please see 17 U.S.C. §512(c)(3) for the requirements of a proper
        notification.
      </p>
      <p>
        You should note that if you knowingly misrepresent in your notification
        that the material or activity is infringing, you may be liable for any
        damages, including costs and attorneys' fees, incurred by TravelFeed or
        the alleged infringer, as the result of TravelFeed&#39;s relying upon
        such misrepresentation in or disabling access to the material or
        activity claimed to be infringing.
      </p>
      <p>
        You should also note that while TravelFeed may have the ability to
        remove access to infringed content on our own platforms, TravelFeed has
        no ability to remove content from supported third-party blockchain
        networks or affect access by any other third-party to any content stored
        on supported third-party blockchains. The reason being that, if you're
        using our content distribution service, transactions sent to supported
        third-party blockchains are not stored on TravelFeed nor does TravelFeed
        have any ability to modify or delete what is on these supported
        third-party blockchains (See 10.7 and 10.8). Additionally, no
        third-party has the technical or effectual ability to modify or delete
        content found on supported third-party blockchains just their ability to
        display the content in question.
      </p>
      <p>
        In addition proper notification given to TravelFeed does not have to be
        related to other third-party services and should not be expected to have
        any impact on any third-party interface to supported third-party
        blockchain networks. Likewise notification to other third-party
        interfaces of supported third-party blockchains should not be expected
        to be related to TravelFeed.
      </p>
      <Typography gutterBottom variant="h5">
        13. Disclaimers
      </Typography>
      <p>
        To the fullest extent permitted by applicable law, TravelFeed and the
        Travelfeed content are provided on an "as is" and "as available" basis,
        without warranties of any kind, either express or implied, including,
        without limitation, implied warranties of merchantability, fitness for a
        particular purpose, title and non-infringement and any warranties
        implied by any course of performance or usage of trade. TravelFeed does
        not represent or warrant that TravelFeed and the TravelFeed content: (a)
        Will be secure or available at any particular time or location; (b) Are
        accurate, complete, reliable, current or error-free or that any defects
        or errors will be corrected; and (c) Are free of viruses or other
        harmful components. Your use of TravelFeed and the TravelFeed content is
        solely at your own risk. Some jurisdictions do not allow the disclaimer
        of implied terms in contracts with consumers, so some or all of the
        disclaimers in this section may not apply to you.
      </p>
      <Typography gutterBottom variant="h5">
        14. Limitation of Liability
      </Typography>
      <p>
        To the fullest extent permitted by applicable law, in no event shall
        TravelFeed or the TravelFeed parties be liable for any special,
        indirect, incidental, consequential, exemplary or punitive damages, or
        any other damages of any kind, including, but not limited to, loss of
        use, loss of profits or loss of data, whether in an action in contract,
        tort (including, but not limited to, negligence) or otherwise, arising
        out of, or in any way connected with, the use of, or inability to use,
        TravelFeed or the TravelFeed content. To the fullest extent permitted by
        applicable law, in no event shall the aggregate liability of TravelFeed
        or the TravelFeed parties, whether in contract, warranty, tort
        (including negligence, whether active, passive or imputed), product
        liability, strict liability or other theory, arising out of or relating
        to: (a) The use of or inability to use TravelFeed or the TravelFeed
        content; or (b) These terms exceed any compensation you pay, if any, to
        TravelFeed for access to or use of TravelFeed.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion or limitation of certain
        damages, so some or all of the exclusions and limitations in this
        section may not apply to you.
      </p>

      <Typography gutterBottom variant="h5">
        15. User Generated Content
      </Typography>
      <p>
        The application may invite you to chat, participate in comments or
        publish blogs and may provide you with the opportunity to create, post
        or broadcast content, including but not limited to text, writings,
        video, audio, photographs, graphics, comments, suggestions, or personal
        information or other material ("contributions"). If you link a
        third-party blockchain account, you may also make contributions through
        third-party applications using your blockchain account that may be
        automatically synced to TravelFeed. Contributions may be viewable by
        other users of the application and through third-party websites or
        applications. As such, any contributions you transmit may be treated as
        non-confidential and non-proprietary. When you create or make available
        any contributions, you guarantee that:
      </p>
      <ol>
        <li>
          The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </li>
        <li>
          You are the creator and owner of or have the necessary licenses,
          rights, consents, permissions, and releases to use and to authorize us
          and users of the application to use your contributions in any manner
          contemplated by the application and these Terms of Service.
        </li>
        <li>
          You have the written consent, release, and/or permission of each and
          every identifiable individual person in your contributions to use the
          name or likeness or each and every such identifiable individual person
          to enable inclusion and use of your contributions in any manner
          contemplated by the application and these Terms of Service.
        </li>
      </ol>
      <p>
        You also guarantee that you contributions are not in violation of the
        TravelFeed community guidelines:
      </p>
      <ol>
        <li>Your contributions are not false, inaccurate, or misleading.</li>
        <li>
          Your contributions are not unsolicited or unauthorized advertising,
          promotional materials, pyramid schemes, chain letters, spam, mass
          mailings, or other forms of solicitation.
        </li>
        <li>
          Your contributions are not obscene, lewd, lascivious, filthy, violent,
          harassing, libelous, slanderous, or otherwise objectionable (as
          determined by us).
        </li>
        <li>
          Your contributions do not ridicule, mock, disparage, intimidate, or
          abuse anyone.
        </li>
        <li>
          Your contributions are not used to harass or threaten (in the legal
          sense of those terms) any other person and to promote violence against
          a specific person or class of people.
        </li>
        <li>
          Your contributions do not violate any applicable law, regulation, or
          rule.
        </li>
        <li>
          Your contributions do not violate the privacy or publicity rights of
          any third party.
        </li>
        <li>
          Your contributions do not contain any material that solicits personal
          information from anyone under the age of 18 or exploits people under
          the age of 18 in a sexual or violent manner.
        </li>
        <li>
          Your contributions do not violate any applicable law concerning child
          pornography, or otherwise intended to protect the health or well-being
          of minors.
        </li>
        <li>
          Your contributions do not include any offensive comments that are
          connected to race, national origin, gender, sexual preference, or
          physical handicap.
        </li>
        <li>
          Your contributions do not otherwise violate, or link to material that
          violates, any provision of these Terms of Service, or any applicable
          law or regulation. Any use of the application in violation of the
          foregoing violates these Terms of Service and may result in, among
          other things, termination or suspension of your rights to use the
          application.
        </li>
      </ol>
      <Typography gutterBottom variant="h5">
        16. Contribution License
      </Typography>
      <p>
        By posting your contributions to any part of the application or through
        other Hive frontends (by using the code snippet or by interacting with
        Hive posts that are on TravelFeed), you automatically grant, and you
        represent and warrant that you have the right to grant, to us a
        non-exclusive, transferable, sub-licensable and worldwide licence to
        host, use, distribute, modify, run, copy, publicly display, monetize,
        translate and create derivative works of your content. We may offer you
        the possibility to grant additional licenses to us and third-parties.
      </p>
      <p>
        You have the option to opt out{' '}
        <Link href="/help/article/hive#opt-out">as described here</Link> from
        having posts made through other Hive frontends using the code snippet
        shown on TravelFeed.
      </p>
      <p>
        This license will apply to any form, media, or technology now known or
        hereafter developed, and includes our use of your name, company name,
        and franchise name, as applicable, and any of the trademarks, service
        marks, trade names, logos, and personal and commercial images you
        provide.
      </p>
      <p>
        We do not assert any ownership over your contributions. You retain full
        ownership of all of your contributions and any intellectual property
        rights or other proprietary rights associated with your contributions.
        We are not liable for any statements or representations in your
        contributions provided by you in any area in the application. You are
        solely responsible for your contributions to the application and you
        expressly agree to exonerate us from any and all responsibility and to
        refrain from any legal action against us regarding your contributions.
      </p>
      <p>
        We have the right, in our sole and absolute discretion, (1) to edit,
        redact, or otherwise change any contributions; (2) to re-categorize any
        contributions to place them in more appropriate locations in the
        application; and (3) to pre-screen or delete any contributions at any
        time and for any reason, without notice. We have no obligation to
        monitor your contributions.
      </p>
      <Typography gutterBottom variant="h5">
        17. Cancellation & Refunds for TravelFeed Hosting
      </Typography>
      <p>
        If you are on a free trial and have not selected a plan, there is no
        need to cancel as your trial will end automatically. If you have already
        selected a plan but have not been charged yet, you will not be charged
        if you cancel the plan before the end of your trial. Your access to
        hosting will end at the end of your free trial period.
      </p>
      <p>
        For paid plans, you may cancel your subscription at any time. Upon
        cancellation, you will not be billed again and your plan will remain
        active until the end of the previously paid subscription period (monthly
        or annually).
      </p>
      <p>
        If you cancel your paid subscription within the first 14 days, you have
        the right to request a refund. In this case, your plan will end
        immediately and you may request a refund by using our{' '}
        <a href="/about/contact" target="_blank">
          contact form
        </a>
        . Any days that you have already used the paid hosting services will be
        deducted from your refund proportionally.
      </p>
      <p>
        You may switch plans at any time. The new plan will be applied
        immediately. If you switch to a cheaper plan, you will receive the
        difference to previously made payments as a credit to your account. This
        credit will be applied to future payments automatically before charging
        your payment method.
      </p>
      <Typography gutterBottom variant="h5">
        18. Fair Usage Policy
      </Typography>
      <p>
        By using TravelFeed Hosting, you agree to adhere to our fair use policy.
        If the traffic to your blog consistently exceeds certain thresholds, we
        reserve the right to request that you upgrade to a higher plan to
        accommodate the increased hosting costs. These thresholds are currently
        set at 10,000 visitors per month for the Basic plan and 100,000 visitors
        per month for the Pro plan.
      </p>
      <Typography gutterBottom variant="h5">
        19. Modifications to TravelFeed
      </Typography>
      <p>
        TravelFeed reserves the right to modify or discontinue, temporarily or
        permanently, TravelFeed, or any features or portions of TravelFeed,
        without prior notice. You agree that TravelFeed will not be liable for
        any modification, suspension or discontinuance of TravelFeed, or any
        part of TravelFeed.
      </p>
      <Typography gutterBottom variant="h5">
        20. Termination
      </Typography>
      <p>
        TravelFeed reserves the right, without notice and in our sole
        discretion, to terminate your license to access and use TravelFeed, to
        delete or deactivate all related information and files (such as, but not
        limited to, TravelFeed settings and drafts and your profile) and to
        block or prevent your future access to, and use of, TravelFeed or its
        app.
      </p>
      <Typography gutterBottom variant="h5">
        21. Severability
      </Typography>
      <p>
        If any term, clause or provision of these terms is deemed to be
        unlawful, void or for any reason unenforceable, then that term, clause
        or provision shall be deemed severable from these terms and shall not
        affect the validity and enforceability of any remaining provisions.
      </p>
      <Typography gutterBottom variant="h5">
        22. Questions &amp; Contact Information
      </Typography>
      <p>Questions or comments about TravelFeed may be directed to:</p>
      <ul>
        <li>
          By email:{' '}
          <a
            href="mailto:contact@travelfeed.com"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            contact@travelfeed.com
          </a>
        </li>
        <li>
          By visiting our Discord:{' '}
          <a
            href="https://discord.gg/jWWu73H"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            https://discord.gg/jWWu73H
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Terms;
