import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query profile($username: String!) {
    profile(username: $username) {
      author {
        username
        displayName
        roles
        avatar
      }
      coverImage
      about
      website
      twitter
      facebook
      instagram
      youtube
      pinterest
      hive
      following
      followers
      posts
    }
  }
`;

export const GET_SHORT_PROFILE = gql`
  query profile($username: String!) {
    profile(username: $username) {
      author {
        roles
        avatar
      }
      about
      twitter
      facebook
      instagram
      youtube
      pinterest
    }
  }
`;

export const GET_AVATAR = gql`
  query profile($username: String!) {
    profile(username: $username) {
      author {
        avatar
        roles
      }
    }
  }
`;

export const GET_HIVE_USERNAME = gql`
  query profile($username: String!) {
    profile(username: $username) {
      hive
    }
  }
`;

export const GET_IS_FOLLOWED = gql`
  query isFollowed($author: String, $community: Int) {
    isFollowed(author: $author, community: $community)
  }
`;

export const NEW_USERS = gql`
  query newUsers {
    newUsers {
      username
      displayName
      avatar
    }
  }
`;

export const GET_BADGES = gql`
  query getBadges($user: String!, $onlyUnseen: Boolean) {
    getBadges(user: $user, onlyUnseen: $onlyUnseen) {
      type
      name
    }
  }
`;

export const GET_BADGES_OWNERS = gql`
  query getBadgeOwners($type: String!, $name: String!) {
    getBadgeOwners(type: $type, name: $name)
  }
`;
